<template>
  <main>
    <div class="text-center animated fadeInDown">
      <img
        src="https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=500&amp;name=404%20error.png"
        class="hs-image-widget"
        style="width: 500px; border-width: 0px; border: 0px"
        width="500"
        alt="404 error - oops, you must be lost"
        title="404 error - oops, you must be lost"
        srcset="
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=250&amp;name=404%20error.png   250w,
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=500&amp;name=404%20error.png   500w,
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=750&amp;name=404%20error.png   750w,
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=1000&amp;name=404%20error.png 1000w,
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=1250&amp;name=404%20error.png 1250w,
          https://www.getbackbar.com/hs-fs/hubfs/Web%20Design%20Assets%20/Illustrations/Error%20pages%20/404%20error.png?width=1500&amp;name=404%20error.png 1500w
        "
        sizes="(max-width: 500px) 100vw, 500px" />
    </div>
    <div class="text-center animated fadeInUp">
      <h3>
        Return to
        <router-link to="/">backbar.app</router-link>
      </h3>
    </div>
  </main>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.body.classList.add('gray-bg')
  }
})
</script>
