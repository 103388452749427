<template>
  <button
    :class="[
      buttonType,
      optionalClasses,
      { 'btn-small': small, 'round-button': round },
      'new-button'
    ]"
    :disabled="isDisabled">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ButtonTypes } from '@/enums/components.enum'

export default defineComponent({
  name: 'ButtonComponent',
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    optionalClasses: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: ButtonTypes.Default,
      validator: (value: ButtonTypes): boolean => Object.values(ButtonTypes).includes(value)
    },
    small: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    }
  }
})
</script>
<style lang="stylus">
// button types have their associated classes with the same name.
@import '../assets/css/variables.styl'

.new-button
  font-family: $hfont
  min-height: $button-height
  font-size: 14px
  font-weight: 700
  border: none
  border-radius: $border-radius-sm
  padding: $padding-xs $padding-md
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75)
  cursor pointer
  transition: background-color 0.15s ease
  &:disabled
    background-color $button-disabled !important
    color $text-disabled !important

  &.primary
    background-color: $action-primary
    color: $text-on-primary
    &:not(:disabled):hover
      background-color: $action-primary-hover
    &:not(:disabled):active
      background-color: $action-primary-pressed

  &.secondary
    background-color $action-secondary
    color $text-on-secondary
    border $border-default solid 1px
    &:not(:disabled):hover
      background-color: $action-secondary-hover
    &:not(:disabled):active
      background-color: $action-secondary-pressed

  &.critical
    background-color $action-critical
    color $text-on-critical
    &:not(:disabled):hover
      background-color: $action-critical-hover
    &:not(:disabled):active
      background-color: $action-critical-pressed

.btn-small
  height: $button-height-small
  line-height: $line-height
  padding: $padding-xs $padding-sm

.round-button {
  height: 28px
  width: 28px
  padding: 2px
  border-radius: 50%
}
</style>
