// Utilities for the shopping cart flow
import debounce from 'lodash/debounce'
import { useGtmStore } from '@/stores/google-tag-manager'
import type {
  ShoppingCartItemWithPricesType,
  VendorCart,
  VendorsWithShoppingCartItems
} from '@/types/item.type'
import { pluralizeWord } from '@/js/utils'
import type { PostShoppingCartItem } from '@/types/shopping-cart-item.type'

export function getCaseMultiplier(item: ShoppingCartItemWithPricesType): number {
  if (item.unit_or_case !== 'case' || item.container_name === 'keg') {
    return 1
  }

  if (item.units_per_case) {
    return item.units_per_case
  }

  const defaultMultipliers: { [key: number]: number } = {
    1: 12,
    2: 24,
    3: 12
  }

  return defaultMultipliers[item.type] || 1
}

export function triggerGTM(carts: VendorsWithShoppingCartItems | undefined) {
  const gtmStore = useGtmStore()
  gtmStore.vendorCarts = carts
}

type SyncItemType = (item: ShoppingCartItemWithPricesType) => Promise<void>

export function getDebouncedSyncItem(
  debounceItemCalls: { [key: string]: any },
  item: ShoppingCartItemWithPricesType,
  syncItem: SyncItemType
) {
  if (!debounceItemCalls[item.id]) {
    debounceItemCalls[item.id] = {
      debouncedFunc: debounce(async () => {
        await syncItem(debounceItemCalls[item.id].latestItem)
      }, 1000),
      latestItem: item
    }
    debounceItemCalls[item.id].debouncedFunc()
  } else {
    debounceItemCalls[item.id].latestItem = item
    debounceItemCalls[item.id].debouncedFunc()
  }
  return debounceItemCalls[item.id].debouncedFunc
}

export type ActionType = 'TYPE' | 'INC' | 'DEC' | 'DEL' | 'CONTAINER_CHANGE'
type UpdateItemProps = {
  updateAction: ActionType
  itemsWithPrices: ShoppingCartItemWithPricesType[]
  shoppingCartItems: ShoppingCartItemWithPricesType[]
  vendorCarts: VendorsWithShoppingCartItems
  item: ShoppingCartItemWithPricesType
  debounceItemCalls: { [key: string]: any }
  syncItem: (item: ShoppingCartItemWithPricesType) => Promise<void>
  getSummary: (itemsWithPrices: ShoppingCartItemWithPricesType[]) => void
}

export async function updateAndSyncItem(props: UpdateItemProps) {
  const {
    updateAction,
    itemsWithPrices,
    shoppingCartItems,
    vendorCarts,
    item,
    debounceItemCalls,
    syncItem,
    getSummary
  } = props

  const itemListItem = itemsWithPrices.find((i) => i.id === item.id)
  if (!itemListItem) return

  const shoppingCartItem = getOrCreateShoppingCartItem(itemListItem, shoppingCartItems)
  shoppingCartItem.unit_or_case ||= 'unit'

  const vendorCart = getOrCreateVendorCart(item.vendor_id || 0, vendorCarts, item)
  const vendorCartItem = getOrCreateVendorCartItem(item, vendorCart)

  const newQuantity = calculateNewQuantity(updateAction, shoppingCartItem, item)

  updateShoppingCartItems(shoppingCartItems, shoppingCartItem, item, newQuantity)
  updateItemQuantities(itemsWithPrices, item, newQuantity, vendorCartItem)

  getDebouncedSyncItem(debounceItemCalls, item, syncItem)()
  getSummary(shoppingCartItems)
}

function getOrCreateShoppingCartItem(
  itemListItem: ShoppingCartItemWithPricesType,
  shoppingCartItems: ShoppingCartItemWithPricesType[]
): ShoppingCartItemWithPricesType {
  const shoppingCartItem = shoppingCartItems.find((i) => i.id === itemListItem.id) || itemListItem
  if (!shoppingCartItems.includes(shoppingCartItem)) {
    shoppingCartItems.unshift(itemListItem)
  }
  return shoppingCartItem
}

function getOrCreateVendorCart(
  vendorId: number,
  vendorCarts: VendorsWithShoppingCartItems,
  item: ShoppingCartItemWithPricesType
): VendorCart {
  if (!vendorCarts[vendorId]) {
    vendorCarts[vendorId] = { totalCartCost: 0, cartItems: [] }
  }
  if (!vendorCarts[vendorId].cartItems.length) {
    vendorCarts[vendorId].cartItems = [item]
  }
  return vendorCarts[vendorId]
}

function getOrCreateVendorCartItem(
  item: ShoppingCartItemWithPricesType,
  vendorCart: VendorCart
): ShoppingCartItemWithPricesType {
  let vendorCartItem = vendorCart.cartItems.find((i) => i.id === item.id)
  if (!vendorCartItem) {
    vendorCart.cartItems.unshift(item)
    vendorCartItem = vendorCart.cartItems.find(
      (i) => i.id === item.id
    ) as ShoppingCartItemWithPricesType
  }
  return vendorCartItem
}

function calculateNewQuantity(
  updateAction: string,
  shoppingCartItem: ShoppingCartItemWithPricesType,
  item: ShoppingCartItemWithPricesType
): number | undefined {
  const newQuantity = shoppingCartItem.order_quantity || 0

  switch (updateAction) {
    case 'INC':
      return newQuantity + 1
    case 'DEC':
      if (!item.order_quantity || item.order_quantity - 1 <= 0) return undefined
      return Math.max(newQuantity - 1, 0)
    case 'DEL':
      return undefined
  }
  return newQuantity
}

function updateShoppingCartItems(
  shoppingCartItems: ShoppingCartItemWithPricesType[],
  shoppingCartItem: ShoppingCartItemWithPricesType,
  item: ShoppingCartItemWithPricesType,
  newQuantity: number | undefined
) {
  if (newQuantity === undefined) {
    shoppingCartItems.splice(
      shoppingCartItems.findIndex((i) => i.id === item.id),
      1
    )
  } else {
    shoppingCartItem.order_quantity = newQuantity
    shoppingCartItem.unit_or_case = item.unit_or_case
  }
}

function updateItemQuantities(
  itemsWithPrices: ShoppingCartItemWithPricesType[],
  item: ShoppingCartItemWithPricesType,
  newQuantity: number | undefined,
  vendorCartItem: ShoppingCartItemWithPricesType
) {
  const itemWithPricesIndex = itemsWithPrices.findIndex((i) => i.id === item.id)
  itemsWithPrices[itemWithPricesIndex].order_quantity = newQuantity
  item.order_quantity = newQuantity
  vendorCartItem.order_quantity = newQuantity
  vendorCartItem.unit_or_case = item.unit_or_case
  vendorCartItem.isLoadingPrices = true
}

export function getPluralizedWord(word: string, quantity: number) {
  if (word) {
    return pluralizeWord(word, quantity)
  }
  return word ? word : ''
}

export function updateVendorCartItem(
  item: ShoppingCartItemWithPricesType,
  data: PostShoppingCartItem,
  vendorCarts: VendorsWithShoppingCartItems
) {
  const vendorCart = vendorCarts[item.vendor_id]
  if (!vendorCart) return

  const vendorCartItems = vendorCart.cartItems
  const vendorCartItemIndex = vendorCartItems.findIndex((i) => i.id === item.id)
  const currentItem = vendorCartItems[vendorCartItemIndex]

  if (currentItem) {
    const { cost_estimate, cost_difference, isOutOfStock, order_quantity } = data
    currentItem.order_quantity = order_quantity
    currentItem.cost_estimate = cost_estimate
    currentItem.cost_difference = cost_difference
    currentItem.isOutOfStock = isOutOfStock
    currentItem.isLoadingPrices = false
  }
}

export function updateSavedForLaterItem(
  item: ShoppingCartItemWithPricesType,
  data: PostShoppingCartItem,
  savedForLater: ShoppingCartItemWithPricesType[]
) {
  if (!savedForLater) return

  const savedItem = savedForLater.find((savedItem) => savedItem.id === item.id)
  if (savedItem) {
    const { cost_estimate, cost_difference, isOutOfStock, order_quantity } = data
    savedItem.order_quantity = order_quantity
    savedItem.cost_estimate = cost_estimate
    savedItem.cost_difference = cost_difference
    savedItem.isOutOfStock = isOutOfStock
    savedItem.isLoadingPrices = false
  }
}
