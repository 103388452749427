import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export const BackbarStylingPreset = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: 'var(--border-radius-sm)',
      md: 'var(--border-radius)',
      lg: '8px',
      xl: '12px'
    }
  },
  semantic: {
    iconSize: '14px',
    formField: {
      paddingX: 'var(--padding-sm)',
      paddingY: 'var(--padding-sm)',
      borderRadius: '{border.radius.sm}'
    },
    disabledOpacity: '1',
    colorScheme: {
      light: {
        formField: {
          hoverColor: 'var(--color-surface-hover)',
          color: 'var(--color-text)',
          borderColor: 'var(--border-default-color)',
          hoverBorderColor: 'var(--border-hover-color)',
          focusBorderColor: 'var(--border-pressed-color)',
          placeholderColor: 'var(--color-text-subdued)'
        },
        overlay: {
          select: {
            hoverColor: 'var(--color-surface-hover)',
            focusColor: 'var(--color-text)'
          }
        },
        list: {
          option: {
            color: 'var(--color-text-default)',
            focusColor: 'var(--color-text-default)',
            selectedColor: 'var(--color-text-default)',
            selectedFocusColor: 'var(--color-text-default)',
            focusBackground: 'var(--color-surface-hover)',
            selectedFocusBackground: 'var(--color-surface-hover)',
            selectedBackground: 'transparent'
          }
        },
        text: {
          color: 'var(--color-text-default)'
        },
        primary: {
          color: '{zinc.950}',
          inverseColor: '#ffffff',
          hoverColor: '{zinc.900}',
          activeColor: '{zinc.800}'
        },
        highlight: {
          background: 'var(--color-surface-hover)',
          focusBackground: 'var(--color-surface-hover)',
          color: 'var(--color-text-default)',
          focusColor: '#ffffff'
        }
      }
    }
  },
  components: {
    skeleton: {
      root: {
        borderRadius: 'var(--border-radius-sm)'
      },
      colorScheme: {
        light: {
          root: {
            background: 'var(--color-surface-header)',
            animationBackground: 'rgba(255,255,255,0.4)'
          }
        }
      }
    },
    button: {
      root: {
        borderRadius: 'var(--border-radius-sm)',
        roundedBorderRadius: 'var(--border-radius-sm)',
        gap: '0.5rem',
        paddingX: 'calc(var(--padding-md) - 1px)',
        paddingY: 'calc(var(--padding-sm) - 1px)',
        iconOnlyWidth: '1.75rem',
        sm: {
          fontSize: '1rem',
          paddingX: '0.75rem',
          paddingY: '0.25rem'
        },
        lg: {
          fontSize: '1.125rem',
          paddingX: '0.875rem',
          paddingY: '0.625rem'
        },
        label: {
          fontWeight: 'var(--button-font-weight)'
        },
        raisedShadow: '0px 1px 1px 0px rgba(219, 223, 223, 0.75)',
        focusRing: {
          width: '{focus.ring.width}',
          style: '{focus.ring.style}',
          offset: '{focus.ring.offset}'
        },
        badgeSize: '1rem',
        transitionDuration: '{form.field.transition.duration}'
      },
      colorScheme: {
        light: {
          root: {
            primary: {
              background: 'var(--color-primary)',
              hoverBackground: 'var(--action-primary-hover)',
              activeBackground: 'var(--action-primary-pressed)',
              borderColor: 'var(--color-primary)',
              hoverBorderColor: 'var(--action-primary-hover)',
              activeBorderColor: 'var(--action-primary-pressed)',
              color: '{primary.contrast.color}',
              hoverColor: '{primary.contrast.color}',
              activeColor: '{primary.contrast.color}',
              focusRing: {
                color: 'var(--color-primary)',
                shadow: 'transparent'
              }
            },
            secondary: {
              background: 'var(--action-secondary)',
              hoverBackground: 'var(--action-secondary-hover)',
              activeBackground: 'var(--action-secondary-pressed)',
              borderColor: 'var(--border-default-color)',
              hoverBorderColor: 'var(--border-default-color)',
              activeBorderColor: 'var(--border-default-color)',
              color: 'var(--color-text)',
              hoverColor: 'var(--color-text)',
              activeColor: 'var(--color-text)',
              focusRing: {
                color: 'var(--color-primary)',
                shadow: 'none'
              }
            },
            info: {
              background: '{sky.500}',
              hoverBackground: '{sky.600}',
              activeBackground: '{sky.700}',
              borderColor: '{sky.500}',
              hoverBorderColor: '{sky.600}',
              activeBorderColor: '{sky.700}',
              color: '#ffffff',
              hoverColor: '#ffffff',
              activeColor: '#ffffff',
              focusRing: {
                color: '{sky.500}',
                shadow: 'none'
              }
            },
            success: {
              background: '{green.500}',
              hoverBackground: '{green.600}',
              activeBackground: '{green.700}',
              borderColor: '{green.500}',
              hoverBorderColor: '{green.600}',
              activeBorderColor: '{green.700}',
              color: '#ffffff',
              hoverColor: '#ffffff',
              activeColor: '#ffffff',
              focusRing: {
                color: '{green.500}',
                shadow: 'none'
              }
            },
            warn: {
              background: '{orange.500}',
              hoverBackground: '{orange.600}',
              activeBackground: '{orange.700}',
              borderColor: '{orange.500}',
              hoverBorderColor: '{orange.600}',
              activeBorderColor: '{orange.700}',
              color: '#ffffff',
              hoverColor: '#ffffff',
              activeColor: '#ffffff',
              focusRing: {
                color: '{orange.500}',
                shadow: 'none'
              }
            },
            help: {
              background: '{purple.500}',
              hoverBackground: '{purple.600}',
              activeBackground: '{purple.700}',
              borderColor: '{purple.500}',
              hoverBorderColor: '{purple.600}',
              activeBorderColor: '{purple.700}',
              color: '#ffffff',
              hoverColor: '#ffffff',
              activeColor: '#ffffff',
              focusRing: {
                color: '{purple.500}',
                shadow: 'none'
              }
            },
            danger: {
              background: 'var(--action-critical)',
              hoverBackground: 'var(--action-critical-hover)',
              activeBackground: 'var(--action-critical-pressed)',
              borderColor: 'var(--action-critical)',
              hoverBorderColor: 'var(--action-critical-hover)',
              activeBorderColor: 'var(--action-critical-pressed)',
              color: '#ffffff',
              hoverColor: '#ffffff',
              activeColor: '#ffffff',
              focusRing: {
                color: 'var(--action-critical)',
                shadow: 'none'
              }
            },
            contrast: {
              background: '{surface.950}',
              hoverBackground: '{surface.900}',
              activeBackground: '{surface.800}',
              borderColor: '{surface.950}',
              hoverBorderColor: '{surface.900}',
              activeBorderColor: '{surface.800}',
              color: '{surface.0}',
              hoverColor: '{surface.0}',
              activeColor: '{surface.0}',
              focusRing: {
                color: '{surface.950}',
                shadow: 'none'
              }
            }
          },
          outlined: {
            primary: {
              hoverBackground: '{primary.50}',
              activeBackground: '{primary.100}',
              borderColor: '{primary.200}',
              color: '{primary.color}'
            },
            secondary: {
              hoverBackground: '{surface.50}',
              activeBackground: '{surface.100}',
              borderColor: '{surface.200}',
              color: '{surface.500}'
            },
            success: {
              hoverBackground: '{green.50}',
              activeBackground: '{green.100}',
              borderColor: '{green.200}',
              color: '{green.500}'
            },
            info: {
              hoverBackground: '{sky.50}',
              activeBackground: '{sky.100}',
              borderColor: '{sky.200}',
              color: '{sky.500}'
            },
            warn: {
              hoverBackground: '{orange.50}',
              activeBackground: '{orange.100}',
              borderColor: '{orange.200}',
              color: '{orange.500}'
            },
            help: {
              hoverBackground: '{purple.50}',
              activeBackground: '{purple.100}',
              borderColor: '{purple.200}',
              color: '{purple.500}'
            },
            danger: {
              hoverBackground: '{red.50}',
              activeBackground: '{red.100}',
              borderColor: '{red.200}',
              color: '{red.500}'
            },
            contrast: {
              hoverBackground: '{surface.50}',
              activeBackground: '{surface.100}',
              borderColor: '{surface.700}',
              color: '{surface.950}'
            },
            plain: {
              hoverBackground: '{surface.50}',
              activeBackground: '{surface.100}',
              borderColor: '{surface.200}',
              color: '{surface.700}'
            }
          },
          text: {
            primary: {
              hoverBackground: 'var(--color-transparent)',
              activeBackground: 'var(--color-transparent)',
              color: 'var(--color-text)',
              hoverColor: 'var(--icon-hover)',
              activeColor: 'var(--icon-pressed)'
            },
            secondary: {
              hoverBackground: 'var(--action-secondary-hover)',
              color: 'var(--color-primary)'
            },
            success: {
              hoverBackground: '{green.50}',
              activeBackground: '{green.100}',
              color: '{green.500}'
            },
            info: {
              hoverBackground: '{sky.50}',
              activeBackground: '{sky.100}',
              color: '{sky.500}'
            },
            warn: {
              hoverBackground: '{orange.50}',
              activeBackground: '{orange.100}',
              color: '{orange.500}'
            },
            help: {
              hoverBackground: '{purple.50}',
              activeBackground: '{purple.100}',
              color: '{purple.500}'
            },
            danger: {
              hoverBackground: '{red.50}',
              activeBackground: '{red.100}',
              color: '{red.500}'
            },
            plain: {
              hoverBackground: '{surface.50}',
              activeBackground: '{surface.100}',
              color: '{surface.700}'
            }
          },
          link: {
            color: '{primary.color}',
            hoverColor: '{primary.color}',
            activeColor: '{primary.color}'
          }
        }
      }
    },
    dialog: {
      color: 'var(--color-text)',
      header: {
        padding: 'var(--padding-lg)'
      },
      footer: {
        padding: 'var(--padding-lg)'
      },
      content: {
        padding: 'var(--padding-lg)'
      },
      border: {
        radius: 'var(--modal-radius)'
      },
      shadow: '0px 4px 24px 0px rgba(12, 28, 25, 0.16)',
      title: {
        font: {
          size: '16px',
          weight: '700'
        }
      }
    },
    select: {
      disabled: {
        color: 'var(--color-text-subdued)',
        background: 'var(--color-surface-neutral)'
      },
      placeholder: {
        color: 'var(--color-text)'
      },
      dropdown: {
        color: 'var(--color-text)',
        width: '2rem'
      },
      overlay: {
        borderColor: 'var(--border-default-color)',
        borderRadius: 'var(--border-radius-sm)'
      },
      list: {
        padding: 'var(--padding-sm)'
      },
      option: {
        color: 'var(--color-text)',
        padding: 'var(--padding-xs)',
        border: {
          radius: 'var(--border-radius-sm)'
        },
        focusBackground: 'var(--color-surface-hover)',
        selectedBackground: 'transparent',
        selectedFocusBackground: 'var(--color-surface-hover)'
      },
      border: {
        color: 'var(--border-default-color)',
        radius: 'var(--border-radius-sm)'
      },
      hover: {
        border: {
          color: 'var(--border-hover-color)'
        }
      },
      focus: {
        border: {
          color: 'var(--border-pressed-color)'
        }
      },
      checkmark: {
        gutter: {
          start: '0',
          end: 'var(--padding-xs)'
        }
      }
    },
    datatable: {
      colorScheme: {
        light: {
          root: {
            borderColor: 'var(--border-default-color)'
          },
          row: {
            stripedBackground: '{surface.50}'
          },
          bodyCell: {
            selectedBorderColor: 'var(--border-default-color)'
          }
        }
      },
      header: {
        background: 'var(--color-surface-header)',
        borderColor: 'var(--border-default-color)',
        color: 'var(--color-text)',
        borderWidth: '1px',
        padding: 'var(--padding-sm) var(--padding-md)'
      },
      headerCell: {
        background: 'var(--color-surface-header)',
        hoverBackground: 'var(--color-surface-hover)',
        selectedBackground: 'var(--color-surface-pressed)',
        borderColor: 'var(--border-default-color)',
        gap: 'var(--padding-sm)',
        padding: 'var(--padding-sm) var(--padding-md)'
      },
      row: {
        background: 'var(--color-surface-default)',
        hoverBackground: 'var(--color-surface-hover)',
        selectedBackground: 'var(--color-surface-pressed)',
        selectedColor: 'var(--color-text)',
        borderColor: 'var(--border-default-color)'
      },
      column: {
        title: {
          font: {
            weight: 'bold'
          }
        }
      },
      bodyCell: {
        borderColor: 'var(--border-default-color)'
      },
      footer: {
        borderColor: 'var(--border-default-color)',
        borderWidth: '0 1px 1px 1px',
        padding: '10px 8px'
      }
    }
  }
})
