<template>
  <ModalComponent key="merge_model" modalWidth="1080px" :isVisible="isOpen" @hide="$emit('onHide')">
    <template #headerContent>
      <h4>Merge duplicate items</h4>
    </template>
    <template #body>
      <DataTable
        v-model:selection="selectedProduct"
        data-key="id"
        :value="items"
        scrollable
        scrollHeight="400px"
        id="datatable-merge-modal">
        <Column
          frozen
          selectionMode="single"
          header="Keep as master item"
          headerStyle="width: 120px; min-width: 120px; max-width: 120px"></Column>
        <Column frozen field="name" header="Name">
          <template #body="{ data }">
            <p>
              <strong>{{ data.name }}</strong>
            </p>
            <p class="d-flex align-items-center">
              {{ data.size_value }}{{ data.size_units_name }} {{ data.container_name }}
              <template v-if="!!data.sku">
                <i class="fa-solid fa-circle mx-1"></i>
                {{ data.sku }}
              </template>
            </p>
          </template>
        </Column>
        <Column field="units_per_case" header="Units per case"></Column>
        <Column field="cost" header="Unit cost">
          <template #body="{ data }">
            {{ data.cost ? `$${data.cost}` : `-` }}
          </template>
        </Column>
        <Column field="par" header="Par">
          <template #body="{ data }">
            {{ data.par === null ? `-` : data.par }}
          </template>
        </Column>
        <Column header="# Orders">
          <template #body="{ data }">
            {{ transactions.filter((tx) => tx.inv_item_id === data.id && tx.type_id === 2).length }}
          </template>
        </Column>
        <Column header="# Sales">
          <template #body="{ data }">
            {{ getSalesCount(data.id) }}
          </template>
        </Column>
        <Column header="# Inventory sessions" headerStyle="width: 170px">
          <template #body="{ data }">
            {{ transactions.filter((tx) => tx.inv_item_id === data.id && tx.type_id === 1).length }}
          </template>
        </Column>
      </DataTable>
    </template>
    <template #footerContent>
      <div class="flex-grow-1 d-flex">
        <i class="fa-solid fa-warning block pt-2 fa-lg" style="margin-right: 8px"></i>
        <p>
          All order and sales data for items will be merged into the master item. Past inventory
          session data will not be affected. Merging items cannot be undone.
        </p>
      </div>
      <ButtonComponent :buttonType="ButtonTypes.Secondary" @click="$emit('onHide')">
        Cancel
      </ButtonComponent>
      <ButtonComponent
        :buttonType="ButtonTypes.Primary"
        :isDisabled="!selectedProduct || isLoading"
        @click="doMerge()">
        {{ !isLoading ? 'Merge' : 'Merging...' }}
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ModalComponent from '@/components/ModalComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { ButtonTypes } from '@/enums/components.enum'
import type { InventoryTransactionType } from '@/types/inventory-transaction.type'
import type { ItemType } from '@/types/item.type'

export default defineComponent({
  components: {
    DataTable,
    Column,
    ModalComponent,
    ButtonComponent
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array<ItemType>,
      default: []
    },
    venueId: {
      type: Number,
      default: null
    }
  },
  watch: {
    async isOpen(open: boolean) {
      if (!open) return
      // reset data to start
      this.selectedProduct = null
      // fetch fresh inventory transactions
      const inventoryTransactionResponses = await Promise.all(
        this.items.map<Promise<InventoryTransactionType[]>>(async (item) => {
          const params = new URLSearchParams({ item_id: item.id, limit_sales: '200' })
          return $.get(`/inventory-transactions?${params.toString()}`)
        })
      )
      this.transactions = inventoryTransactionResponses.flat()
    }
  },
  methods: {
    async doMerge() {
      if (!this.selectedProduct) return
      try {
        this.isLoading = true
        const params = new URLSearchParams({
          targetItemId: this.selectedProduct.id,
          venueId: this.venueId.toString()
        })
        // get all sourceItemIds (excluding the targetItemId)
        this.items.forEach((item) => {
          if (item.id === this.selectedProduct?.id) return
          params.append('sourceItemIds', item.id)
        })
        const response = await $.post(`/items/merge-items?${params.toString()}`)
        if (response?.success === false) {
          throw new Error(response.message.pop())
        }
        this.$alerts.addAlert({
          title: 'Success! ',
          content: 'Successfully merged inventory items.',
          class: 'alert-success'
        })
        this.$emit('onMerged')
        this.$emit('onHide')
      } catch (err) {
        this.$alerts.addAlert({
          title: 'Error: ',
          content: err instanceof Error ? err.message : 'An error occurred trying to merge items.',
          class: 'alert-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    getSalesCount(id: string) {
      const sales = this.transactions.filter(
        (tx) => tx.inv_item_id === id && tx.type_id === 3
      ).length
      if (sales > 100) return '100+'
      else return sales
    }
  },
  data() {
    return {
      ButtonTypes,
      selectedProduct: null as ItemType | null,
      transactions: [] as InventoryTransactionType[],
      isLoading: false
    }
  }
})
</script>
<style>
#datatable-merge-modal.p-datatable .p-datatable-thead > tr > th {
  vertical-align: top;
}

.p-radiobutton-checked .p-radiobutton-box {
  border-color: #0074dd !important;
  background: #0074dd !important;
}

.p-radiobutton-box {
  width: 16px !important;
  height: 16px !important;
  border-color: #313635 !important;
}

.p-radiobutton-icon {
  width: 6px !important;
  height: 6px !important;
}

.fa-warning {
  color: #a97f00;
}

.fa-circle {
  font-size: 3px;
}
</style>
