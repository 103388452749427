<template>
  <ul :class="['nav navbar-top-links d-none d-sm-flex align-items-center']">
    <li>
      <h3 v-if="currentVenue.name">
        {{ currentVenue.name }}
      </h3>
    </li>
    <div :class="['venue-switcher-container']">
      <VenueSwitcherComponent></VenueSwitcherComponent>
    </div>
    <div
      v-if="
        Object.keys(currentVenue).length > 0 &&
        currentVenue.should_verify &&
        user.permissions &&
        user.permissions.manage_vendors &&
        user.permissions.crud_items &&
        user.permissions.modify_organization
      ">
      <RouterLink to="/verify-flow" style="position: relative; color: black">
        <TagsComponent
          :status="TagStatuses.Warning"
          label="Unverified"
          :icon="Icons.Warning"></TagsComponent>
        <div class="unverified-tooltip-container blue-tooltip-container"></div>
      </RouterLink>
    </div>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import VenueSwitcherComponent from '@/components/VenueSwitcherComponent.vue'
import TagsComponent from '../TagsComponent.vue'
import { TagStatuses, Icons } from '@/enums/components.enum'

export default defineComponent({
  components: { VenueSwitcherComponent, TagsComponent },
  name: 'NavBarVenue Component',
  setup() {
    return {
      Icons,
      TagStatuses
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentVenue', 'user'])
  }
})
</script>
