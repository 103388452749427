import { defineStore, acceptHMRUpdate } from 'pinia'
import $ from 'jquery'
import type { SessionOrganizationType } from '@/types/session-organization.type'
import type { SessionVenueType } from '@/types/session-venue.type'
import type { SessionUserType } from '@/types/session-user.type'

interface State {
  user: SessionUserType
  currentVenue: SessionVenueType
  editItemId: string
  editItemMasterId: string
  editItemVipProductId: string
  editRecipeId: string
  currentLocation?: any
  referer: string
  editOrderId: string
  venues: { id: number; name: string }[]
  organizations: { id: number; name: string }[]
  currentOrganization: SessionOrganizationType
  impersonatorId: number | null
}

export const useUserStore = defineStore('user', {
  state: (): State => {
    return {
      user: {} as SessionUserType,
      currentVenue: {} as SessionVenueType,
      editItemId: '',
      editItemMasterId: '',
      editItemVipProductId: '',
      editRecipeId: '',
      currentLocation: {},
      referer: '',
      editOrderId: '',
      venues: [],
      organizations: [],
      currentOrganization: {} as SessionOrganizationType,
      impersonatorId: null
    }
  },
  actions: {
    async getUser() {
      const data = await $.get('/whoami')
      const {
        currentLocation,
        currentOrganization,
        currentVenue,
        editItemId,
        editItemMasterId,
        editItemVipProductId,
        editOrderId,
        editRecipeId,
        organizations,
        referrer,
        user,
        venues
      } = data
      this.currentLocation = currentLocation
      this.currentOrganization = currentOrganization
      this.currentVenue = currentVenue
      this.editItemId = editItemId
      this.editItemMasterId = editItemMasterId
      this.editItemVipProductId = editItemVipProductId
      this.editOrderId = editOrderId
      this.editRecipeId = editRecipeId
      this.organizations = organizations
      this.referer = referrer
      this.user = user
      this.venues = venues
    },
    async logoutUser() {
      const res = await $.get('/logout')
      this.$reset()
      if (res.id) {
        this.impersonatorId = res.id
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
