import { type PrimeVueConfiguration } from 'primevue/config'
import { BackbarStylingPreset } from '@/config/primevuestyle.config'

const primevueConfig: PrimeVueConfiguration = {
  ripple: false,
  locale: {
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  },
  theme: {
    preset: BackbarStylingPreset,
    options: {
      darkModeSelector: '.backbar-dark-mode'
    }
  }
}

export default primevueConfig
