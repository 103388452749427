<template>
  <div class="border-bottom sticky-top">
    <div :class="['nav navbar navbar-static-top white-bg']">
      <Button
        :severity="ButtonTypes.Secondary"
        :icon="Icons.Bars"
        class="navbar-minimalize"
        raised />
      <NavBarVenueComponent></NavBarVenueComponent>
      <NavBarIconMenuComponent></NavBarIconMenuComponent>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ButtonTypes, Icons } from '@/enums/components.enum'
import NavBarVenueComponent from '@/components/NavBar/NavBarVenueComponent.vue'
import NavBarIconMenuComponent from '@/components/NavBar/NavBarIconMenuComponent.vue'
import Button from 'primevue/button'

export default defineComponent({
  components: { NavBarVenueComponent, NavBarIconMenuComponent, Button },
  name: 'NavBar Component',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      ButtonTypes,
      Icons
    }
  }
})
</script>

<style lang="stylus">
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
