<template>
  <p class="label-message">
    <i v-if="icon" :class="[icon, status]"></i>
    {{ label }}
  </p>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Icons, LabelStatuses } from '@/enums/components.enum'

export default defineComponent({
  name: 'LabelComponent',
  props: {
    label: {
      type: String,
      default: 'default'
    },
    icon: {
      type: String as PropType<Icons>,
      default: null,
      required: false
    },
    status: {
      type: String as PropType<LabelStatuses>,
      default: null,
      required: false
    }
  },
  methods: {
    onClick() {
      //This is intentionally empty
    }
  },
  setup() {
    return {
      Icons
    }
  }
})
</script>

<style scoped>
.label-message {
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  display: inline-block;
}

.label-message i {
  margin-right: 4px;
  line-height: 14px;
}

.label-message i.warning {
  color: #a97f00; /* $icon-warning */
}
</style>
