<template>
  <!-- @TODO use dropdown component -->
  <div class="dropdown">
    <i
      v-if="venues.length > 1"
      class="fas fa-sort-down dropdown-icon"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"></i>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <span
        v-for="(venue, key) in venues"
        v-on:click="switchVenue(venue)"
        class="dropdown-item"
        :key="key">
        {{ venue.name }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { useUserStore } from '@/stores/user.js'
import type { VenueType } from '@/types/venue.type'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useBuildFilterStore, useInventoryFilter } from '@/stores/items-filter'

export default defineComponent({
  setup() {
    const resetItemFilters = () => {
      useBuildFilterStore().reset()
      useInventoryFilter().reset()
    }
    return {
      resetItemFilters
    }
  },
  methods: {
    switchVenue(venue: VenueType) {
      const thatVm = this
      localStorage.removeItem('counters')
      $.ajax({
        url: '/venues/switch',
        data: venue,
        method: 'PUT'
      })
        .done((data) => {
          thatVm.clearSessionStorage()
          if (data.success) {
            this.resetItemFilters()
            window.location.reload()
          } else if (data.error === 'invalid_permission') {
            this.$alerts.addAlert({
              title: 'Error: ',
              content: 'Invalid Permission',
              class: 'alert-danger'
            })
          } else {
            this.$alerts.addAlert({
              title: 'Error: ',
              content: 'Something went wrong',
              class: 'alert-danger'
            })
          }
        })
        .fail((data) => {
          this.$alerts.addAlert({
            title: 'Error: ',
            content: 'Something went wrong',
            class: 'alert-danger'
          })
        })
    },
    clearSessionStorage() {
      // We remove only some keys to keep preventing help modals
      window.sessionStorage.removeItem('tagFilter')
      window.sessionStorage.removeItem('subTypeFilter')
      window.sessionStorage.removeItem('searchQuery')
      window.sessionStorage.removeItem('typeFilter')
      window.sessionStorage.removeItem('statusFilter')
      window.sessionStorage.removeItem('vendorFilter')
      window.sessionStorage.removeItem('inventoryLocationsSort')
      window.sessionStorage.removeItem('typeFilterRecipe')
      window.sessionStorage.removeItem('subtypeFilterRecipe')
      window.sessionStorage.removeItem('statusFilterRecipe')
      window.sessionStorage.removeItem('searchQueryRecipe')
      window.sessionStorage.removeItem('startDateFilter')
      window.sessionStorage.removeItem('endDateFilter')
    }
  },
  computed: {
    ...mapState(useUserStore, ['venues'])
  }
})
</script>
<style lang="stylus">
@import '../assets/css/variables.styl'

.dropdown-icon
  height: $button-height-small;
  width: $button-width-small;
  padding: 0 0 $padding-md $padding-sm;
  border-radius: 3px;
  font-size: 1.25rem;
  &:hover
    background-color: $action-secondary-hover
  &:active
      color: $icon-color !important
      background-color: $action-secondary-pressed
</style>
